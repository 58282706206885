




























































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import CrmCategoryLogo from "@/components/CrmCategoryLogo.vue";
import TextInput from "@/components/TextInput.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import { formatDistance, differenceInDays, format } from "date-fns";
import CrmEntry from "@/models/crm-entry";
import CrmEntryApi from "@/services/api/crm-entry";
import { userNameFromId } from "@/models/user";
import { AxiosError, AxiosResponse } from "axios";
import { captureSentryException } from "@/plugins/sentry";
import DeleteButton from "@/components/DeleteButton.vue";
import ErrorService, { ValidationErrors } from "@/services/errors";
@Component({
    components: {
        CrmCategoryLogo,
        DeleteButton,
        LoadingButton,
        TextInput,
        DstvIcon: CrmCategoryLogo,
    },
})
export default class CrmEntryCard extends Vue {
    @Prop({ required: true, type: Object }) crmEntry!: CrmEntry;
    upIcon: string = mdiChevronUp;
    downIcon: string = mdiChevronDown;
    showDetails: boolean = false;
    comment = "";
    isLoading = false;
    formInputErrors: ValidationErrors = new ValidationErrors();
    userNameFromId = userNameFromId;

    formatTimestamp(value: Date): string {
        return format(value, "PPP");
    }

    storeComment(crmEntryId: string) {
        this.isLoading = true;
        this.formInputErrors = new ValidationErrors();
        this.$recaptcha("store_crm_entry_comment")
            .then((captcha) => {
                CrmEntryApi.adminStoreComment(crmEntryId, this.comment, captcha)
                    .then((response: AxiosResponse) => {
                        this.comment = "";
                        this.handleAxiosSuccess(response);
                    })
                    .catch((error: AxiosError) => {
                        this.formInputErrors =
                            ErrorService.getValidationErrors(error);
                        this.handleAxiosError(error);
                    });
            })
            .catch(this.handleAxiosError);
    }

    snooze(crmEntryId: string) {
        this.isLoading = true;
        CrmEntryApi.adminSnooze(crmEntryId)
            .then(this.handleAxiosSuccess)
            .catch(this.handleAxiosError);
    }

    deleteComment(crmEntryId: string, commentId: string) {
        this.isLoading = true;
        CrmEntryApi.adminDeleteComment(crmEntryId, commentId)
            .then(this.handleAxiosSuccess)
            .catch(this.handleAxiosError);
    }

    daysAgo(value: Date): string {
        const diffInDays = differenceInDays(new Date(), value);
        if (diffInDays > 1) {
            return `${diffInDays} days ago`;
        }
        return formatDistance(value, new Date(), { addSuffix: true });
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosSuccess(response: AxiosResponse) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.SUCCESS,
            response.data?.message || response.statusText
        );
        this.emitReloadEvent();

        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    }

    handleAxiosError(error: AxiosError) {
        captureSentryException(error);
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
        this.isLoading = false;
    }

    get xxlBreakpoint(): boolean {
        return window.screen.width > 2000;
    }

    toggleShowProjects() {
        this.showDetails = !this.showDetails;
    }

    emitReloadEvent() {
        this.$emit("reload");
    }
}
